import Axios from "axios";
import store from "@/store";
import router from "@/router";

const api = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "X-Api-Key": process.env.VUE_APP_API_KEY },
  timeout: 0,
});

if (store && store.getters.accessToken)
  api.defaults.headers.common["Authorization"] = store.getters.accessToken;

const responseFailed = (err) => {
  // server error
  if (
    (err === "Error: Network Error" || err.response.status >= 500) &&
    !!store.getters.accessToken
  )
    return signOutLocal(err);

  // unauthorized
  if ([401].includes(err.response.status)) {
    const refreshToken = store.getters.refreshToken;

    // previne infinity sign-out requests
    const ignoredRoutes = ["/auth"];
    const isIgnoredRoute = ignoredRoutes.includes(err.config.url);
    const isLoadSessionRoute = isIgnoredRoute && err.config.method === "get";

    if ((isIgnoredRoute && !isLoadSessionRoute) || !refreshToken)
      return signOutLocal(err);

    // try RefreshToken and resend request
    return api
      .put("/auth/refresh", { refreshToken })
      .then(({ data }) => {
        store.dispatch("setAccessToken", data.accessToken);
        store.dispatch("setRefreshToken", data.refreshToken);

        // 'err.config' is a Original Request
        err.config.headers.Authorization = data.accessToken;

        // resend Request
        const reqResent = Axios(err.config);
        return Promise.resolve(reqResent);
      })
      .catch(() => signOutLocal(err));
  }

  return Promise.reject(err.response);
};

function signOutLocal(err) {
  store.dispatch("setSignOut");
  router.push({ path: "/auth" }).catch(() => {
    /* ignore */
  });
  return Promise.reject(err);
}

// interceptor
api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => responseFailed(err)
);

export default api;
