import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#08A96C",
        button: "#05BE88",
        error: "#DD4B4B",
        info: "#2196F3",
        success: "#05BE88",
        warning: "#FFC66D",
        background: "#1d1f23",
        background1: "#0C1023",
        background2: "#15192F",
        background3: "#374957",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
