import api from "./index";

export async function signIn(body) {
  return api.post("/auth/local", body);
}

export async function signUp(body) {
  return api.post("/auth/local/sign-up", body);
}

export async function loadSession() {
  return api.get("/auth");
}
